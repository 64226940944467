import CustomImg from "components/custom-img/CustomImg";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables";
import detailStyles from "features/events/css/EventDetail.module.scss";
import styles from "features/events/css/EventAdd.module.scss";
import { FunctionComponent, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { renderHtmlContent } from "helpers";
import { EventState } from "../hooks/useEventViewModel";
import { useEventDetailViewModel } from "../hooks/useEventDetailViewModel";
import ParticipationDetails from "./ParticipationDetails";
import EventGallery from "./EventGallery";
import TeamLeaderBoard from "./team-leaderboard/TeamLeaderBoard";
import TeamParticipantDetails, {
  TeamParticipantsDetailRef,
} from "./team-leaderboard/TeamParticipantDetails";
import IndividualLeaderBoard from "./individual-leaderboard/IndividualLeaderBoard";
import { LeaderboardType } from "graphql/_generated/graphql";
import { Skeleton } from "antd";
import { useGetBaseUrl } from "hooks/useGetBaseUrl";

interface EventDetailProps {
  onStatusChange: (status: boolean, loading: boolean) => void;
}

const EventDetail: FunctionComponent<EventDetailProps> = ({
  onStatusChange,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as EventState;
  const vm = useEventDetailViewModel(state);
  const eventStatus = vm.getEventStatus(vm.eventData);
  const baseImgUrl = useGetBaseUrl(vm.region);
  const ref = useRef<TeamParticipantsDetailRef>(null);

  useEffect(() => {
    onStatusChange(eventStatus, vm.isLoadingEvent);
  }, [eventStatus, vm.isLoadingEvent]);

  return (
    <div className={styles["content-section"]}>
      <CollapseAble
        defaultActiveKey={["description"]}
        headerKey="description"
        hideExtra
        expandIconPosition="end"
        header={<ContainerHeading text={t("name-desc")} />}
      >
        <Skeleton
          active
          avatar={{
            shape: "square",
          }}
          loading={vm.isLoadingEvent}
        >
          <div
            className={`${detailStyles["desc-container"]} ${styles["content-card"]}`}
          >
            {vm.eventData?.image && (
              <div className={detailStyles["bg-container"]}>
                <div
                  className={detailStyles["bg-image"]}
                  style={{
                    backgroundImage: `url(${baseImgUrl}${vm.eventData?.image})`,
                  }}
                ></div>
                <div className={`${detailStyles["bg-text"]}`}>
                  <CustomImg
                    imgSrc={vm.eventData?.image}
                    height={270}
                    width={480}
                    alt="thumbnail image"
                    region={vm.region}
                  />
                </div>
              </div>
            )}
            <h1 className={detailStyles["activity-title"]}>
              {vm.eventData?.title}
            </h1>
            <p className={styles["name-desc"]}>
              {renderHtmlContent(vm.eventData?.description ?? "")}
            </p>
          </div>
        </Skeleton>
      </CollapseAble>
      {vm.eventData?.leaderboardType &&
        vm.eventData.leaderboardType === LeaderboardType.Team && (
          <>
            <TeamLeaderBoard
              eventId={state?.id}
              onLeaderboardUpdated={ref.current?.refetchTeamsData}
            />
            <TeamParticipantDetails eventId={state?.id} ref={ref} />
          </>
        )}
      {vm.eventData?.leaderboardType &&
        vm.eventData.leaderboardType === LeaderboardType.Individual && (
          <IndividualLeaderBoard eventId={state?.id} />
        )}
      {vm.eventData?.leaderboardType &&
        vm.eventData.leaderboardType === LeaderboardType.Company && (
          <ParticipationDetails
            loading={vm.isLoadingEvent}
            eventData={vm.eventData}
            onSubmitUpdateScore={vm.onSubmitUpdateParticipantScore}
            visible={vm.participantVisibility}
            onPressEdit={vm.onPressEditParticipant}
            onPressUser={vm.onPressUser}
            userVisibility={vm.userVisibility}
            onCloseUser={vm.onPressCloseUser}
            eventId={state?.id}
            companyId={vm.clientId ?? ""}
          />
        )}
      {vm.eventData?.gallery && vm.eventData?.gallery?.length > 0 ? (
        <EventGallery eventData={vm.eventData} region={vm.region} />
      ) : null}
    </div>
  );
};
export default EventDetail;
