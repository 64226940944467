import * as React from "react";
import { List, Space } from "antd";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import styles from "features/events/css/IndividualLeaderBoard.module.scss";
import { getRanks } from "helpers";

interface IndividualLeaderBoardItemProps {
  itemKey: number;
  id: string;
  userImg: string;
  firstName: string;
  lastName: string;
  companyName: string;
  companyImg: string;
  score: number;
  isKSA: boolean;
}

const IndividualLeaderBoardItem: React.FunctionComponent<
  IndividualLeaderBoardItemProps
> = ({
  id,
  itemKey,
  firstName,
  lastName,
  userImg,
  companyImg,
  companyName,
  score,
  isKSA,
}) => {
  return (
    <List.Item key={id}>
      <div className={styles["item-grid"]}>
        <div>
          <Space size={14}>
            <div className={styles["item-id"]}>{getRanks(itemKey)}</div>
            <CustomAvatar isUserProfile={isKSA} imgSrc={userImg} />
            <div
              className={styles["sub-title"]}
            >{`${firstName} ${lastName}`}</div>
          </Space>
        </div>
        <Space>
          <CustomAvatar imgSrc={companyImg} />
          <div className={styles["members"]}>{companyName}</div>
        </Space>
        <div className={styles["users"]}>
          <div className={styles["count"]}>{score}</div>
        </div>
      </div>
    </List.Item>
  );
};

export default IndividualLeaderBoardItem;
