import { CONFIG } from "helpers/constants";
import { useAppSelector } from "./useStoreHooks";
import { Regions } from "graphql/_generated/graphql";
export const useGetBaseUrl = (defaultRegion: Regions = Regions.Global) => {
  const BASE_IMAGE_URL =
    process.env.REACT_APP_BASE_IMAGE_URL || CONFIG.BASE_IMAGE_URL;
  const KSA_BASE_IMAGE_URL =
    process.env.REACT_APP_KSA_BASE_IMAGE_URL || CONFIG.KSA_BASE_IMAGE_URL;

  const region = useAppSelector((state) => state.auth.region);

  if (region === Regions.Ksa && defaultRegion === Regions.Ksa) {
    return KSA_BASE_IMAGE_URL;
  } else return BASE_IMAGE_URL;
};


