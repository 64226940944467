import { FormikHelpers } from "formik";
import {
  GetAllEventsQuery,
  useGetAllEventsQuery,
  useGetClientsTreeQuery,
  useLazyGetAllEventsQuery,
} from "graphql/_generated/graphql";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useFetchDataFromApi } from "hooks/useFetchDataFromApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEvent } from "./useEvent";
import customNotification from "components/custom-notification/CustomNotification";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useAppSelector } from "hooks/useStoreHooks";
import { useGetLeaderboardTypes } from "hooks/useGeLeaderboardTypes";

export const useEventListViewModel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const region = useAppSelector((state) => state.auth.region);
  const { updateEventStatus, deleteEvent } = useEvent();

  const { handleError } = useErrorHandler();
  const { fetchDataFromApi, isLoading: isLoadingEventList } =
    useFetchDataFromApi(true);

  const [filtersData, setFiltersData] = useState<any>(null);
  const [columnsData, setColumnsData] =
    useState<GetAllEventsQuery["getAllEvents"]>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [langVisibility, setLangVisibility] = useState(false);
  const [langCode, setLangCode] = useState<string>();
  const [editId, setEditId] = useState<string | null>(null);
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [eventRecord, setEventRecord] = useState<MappedEventList>();

  const [getEventListFun] = useLazyGetAllEventsQuery();
  const { isFetching, refetch: refetchEvents } = useGetAllEventsQuery();

  const { data: companiesData } = useGetClientsTreeQuery({
    region,
  });
  const { leaderboardTypes } = useGetLeaderboardTypes();

  useEffect(() => {
    fetchDataFromApi(getEventListFun, setColumnsData, filtersData).catch((e) =>
      handleError(e),
    );
  }, [isFetching, filtersData, region]);

  const filtersCallBack = async (filtersData: any) => {
    try {
      setPageSize(filtersData?.pagination?.offset?.pageSize);
      setCurrentPage(filtersData?.pagination?.offset?.page);
      setFiltersData(filtersData);
    } catch (error) {
      handleError(error as string | object);
    }
  };

  type MappedEventList = NonNullable<typeof mappedColumnsData>[0];
  const mappedColumnsData = columnsData?.data?.map((item) => {
    return (
      {
        ...item,
        hasChildren: true,
        children:
          item?.translations && Object.keys(item?.translations).length
            ? Object.keys(item?.translations).map((record: string) => {
                return {
                  ...item,
                  title: item?.translations?.[record]?.title,
                  location:
                    item?.translations?.[record].location ?? item?.location,
                  language: record,
                };
              })
            : null,
      } ?? []
    );
  });

  const onPressAddEvent = () => {
    setLangVisibility(true);
  };

  const onPressUpdateEvent = (
    id: string,
    langCode: string,
    editAble: boolean,
  ) => {
    navigate("/add-event", {
      state: {
        id: id,
        langCode: langCode,
        editAble,
      },
    });
  };

  const onPressEventDetail = (id: string, langCode: string) => {
    navigate("/event-detail", {
      state: {
        id: id,
        langCode: langCode,
        addLanguage: false,
      },
    });
  };

  const onPressAddLang = (id: string, record: MappedEventList) => {
    setEditId(id);
    setLangVisibility(true);
    setIsAddLanguage(true);
    setEventRecord(record);
  };

  const onPressUpdateLanguage = (id: string, langCode: string) => {
    navigate("/add-event", {
      state: {
        id: id,
        langCode: langCode,
        addLanguage: true,
      },
    });
  };

  const onLanguageSubmit = (
    values: { language: string },
    actions?: FormikHelpers<typeof values>,
  ) => {
    setLangCode(values?.language);
    onCloseLangDialog();
    actions?.resetForm();
    navigate("/add-event", {
      state: {
        langCode: values.language,
        id: editId,
        addLanguage: isAddLanguage,
      },
    });
  };

  const onCloseLangDialog = () => {
    setLangVisibility(false);
    setIsAddLanguage(false);
    setEventRecord(undefined);
  };

  const onToggleEventStatus = async (id: string) => {
    try {
      const isActive = await updateEventStatus({
        eventId: id,
      });
      customNotification(
        "success",
        isActive ? t("event.activated") : t("event.deactivated"),
      );
      refetchEvents();
      return isActive;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const onDeleteEvent = async (id: string) => {
    try {
      const res = await deleteEvent(id);
      customNotification("success", t("event.delete-success"));
      refetchEvents();
      return res;
    } catch (error) {
      handleError(error as string | object);
    }
  };

  const getEventStatuses = (record: MappedEventList) => {
    const { startDate, expiryDate } = record;
    const eventStartDate = moment(startDate);
    const eventExpiryDate = moment(expiryDate);
    const todayDate = moment();
    const isExpired = eventExpiryDate < todayDate;
    const isInProgress =
      eventStartDate <= todayDate && eventExpiryDate >= todayDate;
    const isPlanned = eventStartDate > todayDate;
    const isActive = record.isActive;
    return { isExpired, isInProgress, isPlanned, isActive };
  };
  return {
    pageSize,
    currentPage,
    langVisibility,
    langCode,
    editId,
    isAddLanguage,
    columnsData: mappedColumnsData,
    total: columnsData?.total,
    companiesData: companiesData?.getClientsTree,
    eventTypes: leaderboardTypes,
    eventRecord,
    region,
    filtersCallBack,
    onCloseLangDialog,
    onLanguageSubmit,
    onPressAddEvent,
    onPressUpdateEvent,
    onPressEventDetail,
    onPressUpdateLanguage,
    onPressAddLang,
    onToggleEventStatus,
    onDeleteEvent,
    getEventStatuses,
    loading: {
      isLoadingEventList,
    },
  };
};
