import { DeleteFilled } from "@ant-design/icons";
import AddOption from "components/custom-button/AddOption";
import DeleteBtn from "components/custom-button/DeleteBtn";
import { CustomSelectField } from "components/form/custom-select-field/CustomSelectField";
import { CustomTextArea } from "components/form/custom-text-area/CustomTextArea";
import { CustomTextField } from "components/form/custom-text-field/CustomTextField";
import {
  filterLanguagesWithout,
  getLabelFromValue,
} from "features/content/utils";
import styles from "features/units/css/Units.module.scss";
import { FieldArray } from "formik";
import { MeasurementCreate } from "graphql/_generated/graphql";
import { useLanguages } from "hooks/useLanguages";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface UnitTranslationProps {
  values: MeasurementCreate;
}

const UnitTranslation: React.FunctionComponent<UnitTranslationProps> = ({
  values,
}) => {
  const { t } = useTranslation();
  const { languagesWithOutEn } = useLanguages();
  return (
    <FieldArray
      name="translations"
      render={({ push, remove }) => (
        <>
          {values.translations &&
            values.translations.map((_item: any, index: number) => (
              <>
                <div className={styles["langs"]}>
                  <CustomSelectField
                    label={t("language")}
                    required={true}
                    value={getLabelFromValue(languagesWithOutEn, values, index)}
                    name={`translations.${index}.lang`}
                    placeholder={t("select-language")}
                    options={filterLanguagesWithout(
                      languagesWithOutEn,
                      values.translations,
                    )}
                    defaultValue={getLabelFromValue(
                      languagesWithOutEn,
                      values,
                      index,
                    )}
                  />
                  <CustomTextField
                    label={t("unit")}
                    name={`translations.${index}.unit`}
                    placeholder={t("enter-unit")}
                  />
                  <div className={styles["delete-btn"]}>
                    <DeleteBtn onClick={() => remove(index)}>
                      <DeleteFilled />
                    </DeleteBtn>
                  </div>
                </div>
                <CustomTextArea
                  label={t("description")}
                  rows={6}
                  name={`translations.${index}.description`}
                  placeholder={t("enter-description")}
                />
              </>
            ))}
          {values.translations && values.translations?.length < 1 && (
            <div className={styles["add-translation"]}>
              <AddOption
                title={t("add-translation")}
                onClick={() =>
                  push({
                    lang: "",
                    unit: "",
                    description: "",
                  })
                }
              />
            </div>
          )}
        </>
      )}
    />
  );
};

export default UnitTranslation;
