import {
  Space,
  Upload,
  UploadProps,
  message,
  Spin,
  Progress,
  Tooltip,
} from "antd";
import { ErrorMessage, useField } from "formik";
import { useImageUpload } from "hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import * as React from "react";
import { useErrorTranslation } from "validations/schema/common/useErrorTranslation";
import { AxiosRequestConfig } from "axios";
import { getImageUrl } from "helpers/getImageUrl";
import { RcFile } from "antd/lib/upload";
import { useTranslation } from "react-i18next";
import uploadImgPlaceholder from "assets/images/imgPlaceHolder.svg";
import styles from "./CustomImageUpload.module.scss";
import { CONFIG } from "helpers/constants";
import {
  CloseCircleFilled,
  LoadingOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { ReactElement, useEffect, useState } from "react";
import { IMG_FILE_TYPES, validateFileSize } from "helpers/validateFileSize";
import SortAbleList from "components/custom-sorter/SortList";
import { SortableItem } from "react-easy-sort";
import { arrayMove } from "react-sortable-hoc";
import { Regions } from "graphql/_generated/graphql";
import { useGetBaseUrl } from "hooks/useGetBaseUrl";

const { Dragger } = Upload;
interface CustomImageUploadProps extends UploadProps {
  name: string;
  onDelete?: (id: string) => void;
  label?: ReactElement | string;
  infoText?: string;
  imageProgress?: number;
  isUpdated?: boolean;
  region?: Regions;
}
type RefType = Record<
  string,
  { id: string; progress: number; imageUrl?: string }
>;

const CustomImageUpload: React.FunctionComponent<CustomImageUploadProps> = (
  props,
) => {
  const {
    name,
    multiple,
    children,
    className,
    label,
    infoText,
    imageProgress,
    onDelete,
    isUpdated,
    region = Regions.Global,
    ...restProps
  } = props;

  const [field, meta, helpers] = useField(name);
  const ref = React.useRef<RefType>({});
  const [files, setFiles] = useState<RefType[]>([]);
  const { errorT } = useErrorTranslation();
  const { t } = useTranslation();
  const baseImgUrl = useGetBaseUrl(region);

  const [progress, setProgress] = useState(0);

  const { handleImageUpload, loading } = useImageUpload();
  const { handleError } = useErrorHandler();

  const handleRemoveImage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    !multiple && helpers.setValue(null);
  };
  let errorDisplayed = false;
  const uploadProps: UploadProps = {
    showUploadList: false,
    beforeUpload: (file, fileList) => {
      const extension = file?.name?.split(".")?.pop()?.toLowerCase();
      const imgFormat = IMG_FILE_TYPES.includes(extension ?? "");
      const isValidSize = validateFileSize(file);
      const isWithinLimit = fileList.length <= 10;
      if (!errorDisplayed) {
        if (!isWithinLimit) {
          errorDisplayed = true;
          message.error(t("max-files"));
        }
        if (!isValidSize) {
          errorDisplayed = true;
          message.error(t("max-file-size"));
        }
        if (!imgFormat) {
          errorDisplayed = true;
          message.error(t("file-extensions"));
        }
      }
      return imgFormat && isValidSize && isWithinLimit;
    },

    customRequest: async ({
      onProgress,
      onError,
      onSuccess,
      data,
      file,
      filename,
      withCredentials,
      action,
      headers,
    }) => {
      try {
        const id = (file as RcFile).uid;
        const formData = new FormData();
        formData.append("body", file);
        const config: AxiosRequestConfig = {
          headers: {
            "Content-Type": (file as RcFile).type,
            ...headers,
          },
          onUploadProgress: (event) => {
            const progress = Math.round(
              (event.loaded / (event?.total ?? 1)) * 100,
            );
            setProgress(progress);
            onProgress && onProgress({ percent: progress });
            const progressObj = ref.current[id];
            const progressDes = { ...progressObj, progress, id };
            ref.current = { ...ref.current, [id]: progressDes };
            const fileArray = Object.entries(ref.current).map(
              ([key, value]) => ({
                [key]: value,
              }),
            );
            setFiles(fileArray);
          },
        };
        const response = await handleImageUpload(
          file as RcFile,
          {
            ...config,
          },
          region,
        );
        const imageUrl = getImageUrl(response) ?? "";
        const imageUrlObj = ref.current[id];
        const imageUrDes = { ...imageUrlObj, imageUrl, id: imageUrl };
        delete ref.current[id];
        ref.current = { ...ref.current, [imageUrl]: imageUrDes };
        const fileArray = Object.entries(ref.current).map(([key, value]) => ({
          [key]: value,
        }));
        setFiles(fileArray);
        helpers.setValue(
          (multiple &&
            Object.values(ref.current).map((item) => item.imageUrl)) ??
            imageUrl,
        );
        onSuccess && onSuccess(response);
      } catch (error) {
        handleError(error as string | object);
      }
    },
  };

  const handleRemoveGalleryImage = (id: string) => {
    const updatedArray = files.filter((obj) => {
      const key = Object.keys(obj)[0];
      return key !== id;
    });
    setFiles(updatedArray);
    delete ref.current[id];
    helpers.setValue(
      multiple && Object.values(ref.current).map((item) => item.imageUrl),
    );
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (field.value && Array.isArray(field.value)) {
      const newGallery = arrayMove(field.value, oldIndex, newIndex);
      helpers.setValue(newGallery);
    }
  };

  useEffect(() => {
    if (isUpdated && multiple) {
      const fileArray = field.value
        .filter((imageUrl: string) => imageUrl) // Filter out empty imageUrl
        .map((imageUrl: string) => ({
          [imageUrl]: {
            id: imageUrl,
            progress: 100,
            imageUrl: imageUrl,
          },
        }));
      setFiles(fileArray);

      const objectFromImageUrlArray: RefType = {};
      field.value.forEach((imageUrl: string) => {
        if (imageUrl) {
          objectFromImageUrlArray[imageUrl] = {
            progress: 100,
            imageUrl,
            id: imageUrl,
          };
        }
      });
      ref.current = objectFromImageUrlArray;
    }
  }, [isUpdated, field.value]);

  return (
    <>
      {(label || infoText) && (
        <Space>
          {label &&
            (React.isValidElement(label) ? (
              label
            ) : (
              <label className="label" htmlFor={name}>
                {label}
              </label>
            ))}
          {infoText && (
            <Tooltip trigger="click" color="#494949" title={<p>{infoText}</p>}>
              <InfoCircleFilled />
            </Tooltip>
          )}
        </Space>
      )}
      {files && Array.isArray(files) && multiple && (
        <SortAbleList onSortEnd={onSortEnd}>
          <div className={styles["gallery-images"]}>
            {files.map((item) => {
              const [key, value] = Object.entries(item)[0];
              const { imageUrl, progress, id } = value;
              return (
                <SortableItem>
                  <div
                    className={`${styles["img-loading"]} ${styles["gallery-view"]}`}
                  >
                    {imageUrl ? (
                      <div className={styles["image-container"]}>
                        <img
                          draggable={false}
                          className={styles["gallery-image"]}
                          src={`${baseImgUrl}${imageUrl}`}
                        />
                        <CloseCircleFilled
                          className={styles["gallery-img-remove"]}
                          onClick={() => handleRemoveGalleryImage(id)}
                        />
                      </div>
                    ) : (
                      <div className={styles["loading-content"]}>
                        <Spin
                          className="custom-spinner"
                          tip={t("uploading-img")}
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 32, color: "black" }}
                              spin
                            />
                          }
                        />
                        <div className={styles["progress"]}>
                          <Progress
                            trailColor="#ADADAD"
                            strokeColor="#121212"
                            size="small"
                            showInfo={false}
                            percent={progress}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </SortableItem>
              );
            })}
            <Dragger
              multiple={multiple}
              name={name}
              accept="image/*"
              className={className ?? "custom-dragger"}
              {...restProps}
              {...uploadProps}
            >
              {loading && (
                <div className={styles["img-loading"]}>
                  <div className={styles["loading-content"]}>
                    <Spin
                      className="custom-spinner"
                      tip={t("uploading-img")}
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 32, color: "black" }}
                          spin
                        />
                      }
                    />
                    <div className={styles["progress"]}>
                      <Progress
                        trailColor="#ADADAD"
                        strokeColor="#121212"
                        size="small"
                        showInfo={false}
                      />
                    </div>
                  </div>
                </div>
              )}
              {children ?? (
                <Space size={16} direction="vertical">
                  <img src={uploadImgPlaceholder} />
                  <div className="content-text">
                    {t("drop-image-here")} &nbsp;
                    <br />
                    {t("click-to-browse")}
                  </div>
                </Space>
              )}
            </Dragger>
          </div>
        </SortAbleList>
      )}
      {!multiple && (
        <Dragger
          multiple={multiple}
          name={name}
          accept="image/*"
          className={className ?? "custom-dragger"}
          {...restProps}
          {...uploadProps}
        >
          {loading ? (
            <div className={styles["img-loading"]}>
              <div className={styles["loading-content"]}>
                <Spin
                  className="custom-spinner"
                  tip={t("uploading-img")}
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 32, color: "black" }}
                      spin
                    />
                  }
                />
                <div className={styles["progress"]}>
                  <Progress
                    trailColor="#ADADAD"
                    strokeColor="#121212"
                    size="small"
                    showInfo={false}
                    percent={!multiple ? progress : undefined}
                  />
                </div>
              </div>
            </div>
          ) : field.value && !multiple ? (
            <div className={styles["image-container"]}>
              <img
                draggable={false}
                className="preview-img"
                src={`${baseImgUrl}${field.value}`}
              />
              <CloseCircleFilled
                className={styles["gallery-img-remove"]}
                onClick={handleRemoveImage}
              />
            </div>
          ) : (
            children ?? (
              <Space size={16} direction="vertical">
                <img src={uploadImgPlaceholder} />
                <div className="content-text">
                  {t("drop-image-here")} &nbsp;
                  <br />
                  {t("click-to-browse")}
                </div>
              </Space>
            )
          )}
        </Dragger>
      )}

      <ErrorMessage
        name={name}
        render={(msg) => {
          return <div className="text-danger">{errorT(msg)}</div>;
        }}
      />
    </>
  );
};

export default CustomImageUpload;
