import { Space } from "antd";
import { focusOnErrorField, renderHtmlContent } from "helpers";
import CustomButton from "components/custom-button/CustomButton";
import { Form, Formik } from "formik";
import {
  eventSchema,
  eventTranslationsSchema,
  eventUpdateSchema,
} from "validations/schema";
import styles from "features/events/css/EventAdd.module.scss";
import Breadcrumbs from "components/Breadcrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import EventDescription from "features/events/event-add/EventDescription";
import EventOptions from "features/events/event-add/EventOptions";
import { EventState, useEventViewModel } from "../hooks/useEventViewModel";
import { useLocation, useNavigate } from "react-router-dom";
import EventThumbnail from "./EventThumbnail";
import EventGallery from "./EventGallery";
import TeamManagement from "./TeamManagement";
import { LeaderboardType } from "graphql/_generated/graphql";

const EventAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as EventState;
  const vm = useEventViewModel(state);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={vm.initialValues}
      validationSchema={
        state?.addLanguage
          ? eventTranslationsSchema
          : state.id
          ? eventUpdateSchema
          : eventSchema
      }
      onSubmit={vm.onSubmit}
    >
      {({ values, setFieldValue, errors, isSubmitting, isValid }) => {
        focusOnErrorField({ errors, isSubmitting, isValid });
        return (
          <Form>
            <Breadcrumbs
              data={[
                {
                  label: t("activities"),
                },
                {
                  label: t("events"),
                },
                {
                  label: state?.id
                    ? `${t("update-event")} - ${t(state?.langCode)}`
                    : `${t("add-new-event")} - ${t(state?.langCode)}`,
                },
              ]}
            />
            <div className={styles["btn-div"]}>
              <h6 className={styles["header"]}>
                {state.id
                  ? `${t("update-event")} - ${t(state?.langCode)}`
                  : `${t("add-new-event")} - ${t(state?.langCode)}`}
              </h6>
              <Space>
                <CustomButton
                  className="link-text-btn"
                  onClick={() => navigate("/events#persist")}
                >
                  {t("common.cancel")}
                </CustomButton>
                <CustomButton
                  loading={vm.isLoadingEvent}
                  className="primary-btn"
                  htmlType="submit"
                >
                  <span>{`${t("save")} ${t("event")}`}</span>
                </CustomButton>
              </Space>
            </div>
            <div className={styles["content-section"]}>
              <EventDescription
                oldTitle={vm.eventData?.oldTitle}
                oldDescription={renderHtmlContent(vm.eventData?.oldDescription)}
                isAddLanguage={!!state.addLanguage}
              />
              <EventOptions
                isAddLanguage={!!state.addLanguage}
                oldLocation={vm.eventData?.oldLocation ?? ""}
                companyDisabled={!values.eventTypeId}
                companiesData={vm.companiesData}
                leaderboardTypesData={vm.LeaderboardTypesData}
                editAble={!!state?.editAble}
                setFiledValue={setFieldValue}
              />
              {values.leaderboardType === LeaderboardType.Team && (
                <TeamManagement values={values} />
              )}
              {!state.addLanguage && (
                <>
                  <EventThumbnail region={vm.region} />
                  <EventGallery isUpdated={!!state.id} region={vm.region} />
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default EventAdd;
