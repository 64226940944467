import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import * as React from "react";
import ContainerHeading from "features/assessment/questions/ContainerHeading";
import { useTranslation } from "react-i18next";
import CustomImageUpload from "components/form/custom-upload-file/CustomImageUpload";
import { Regions } from "graphql/_generated/graphql";

interface EventGalleryProps {
  isUpdated: boolean;
  region?: Regions;
}

const EventGallery: React.FunctionComponent<EventGalleryProps> = ({
  isUpdated,
  region,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <CollapseAble
        defaultActiveKey={["event-gallery"]}
        headerKey="event-gallery"
        hideExtra
        expandIconPosition="end"
        header={<ContainerHeading text={t("photo-gallery")} />}
      >
        <CustomImageUpload
          name="galleryImages"
          multiple
          isUpdated={isUpdated}
          region={region}
        />
      </CollapseAble>
    </div>
  );
};

export default EventGallery;
