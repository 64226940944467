import API from "config/api";
import { Regions } from "graphql/_generated/graphql";
import { useGetBaseUrl } from "hooks/useGetBaseUrl";
import * as React from "react";

interface CustomImgProps extends React.HTMLProps<HTMLImageElement> {
  imgSrc?: string;
  height?: number;
  width?: number;
  fit?: "cover" | "contain" | "fill" | "inside" | "outside";
  region?: Regions;
}

const CustomImg: React.FunctionComponent<CustomImgProps> = (props) => {
  const { imgSrc, height, width, fit, region, ...restProps } = props;
  const baseImgUrl = useGetBaseUrl(region);
  return (
    <img
      src={
        height && width
          ? `${baseImgUrl}${imgSrc}?h=${height}&w=${width}`
          : height && width && fit
          ? `${baseImgUrl}${imgSrc}?h=${height}&w=${width}&fit=${fit}`
          : `${baseImgUrl}${imgSrc}${height ? `?h=${height}` : ""}${
              width ? `?w=${width}` : ""
            }${fit ? `${height || width ? "&" : "?"}fit=${fit}` : ""}`
      }
      {...restProps}
    />
  );
};

export default CustomImg;
