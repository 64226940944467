import { ColumnsType } from "antd/lib/table";
import CustomModal from "components/custom-modal/CustomModal";
import CustomTable from "components/custom-table/CustomTable";
import * as React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "components/custom-button/CustomButton";
import { MoreOutlined } from "@ant-design/icons";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import { TableRowSelection } from "antd/lib/table/interface";
import styles from "features/events/css/TeamLeaderBoard.module.scss";
import { Space } from "antd";
import {
  GetTeamEventMembersQuery,
  RemoveParticipantsInput,
} from "graphql/_generated/graphql";
import CustomAvatar from "components/custom-avatar/CustomAvatar";

interface EditTeamProps {
  isLoadingRemoveTeam: boolean;
  eventId: string;
  teamMemberVisibility: boolean;
  teamEventMembersData?: GetTeamEventMembersQuery["getTeamEventMembers"];
  loading: boolean;
  teamEventMemberPageSize: number;
  teamEventMemberCurrentPage: number;
  teamEventMemberSelectedKeys: string[];
  teamEventMemberRowSelection: TableRowSelection<
    GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0]
  >;
  onCloseTeamMember: () => void;
  teamEventMembersFiltersCallBack: (filtersData: any) => Promise<void>;
  onSubmitRemoveTeamMember: (values: RemoveParticipantsInput) => Promise<void>;
  onPressAddMember: () => void;
  onPressChangeTeam: (id?: string) => void;
}

const EditTeam: React.FunctionComponent<EditTeamProps> = ({
  eventId,
  teamMemberVisibility,
  teamEventMembersData,
  loading,
  teamEventMemberCurrentPage,
  teamEventMemberPageSize,
  teamEventMemberRowSelection,
  teamEventMemberSelectedKeys,
  isLoadingRemoveTeam,
  onCloseTeamMember,
  teamEventMembersFiltersCallBack,
  onSubmitRemoveTeamMember,
  onPressAddMember,
  onPressChangeTeam,
}) => {
  const { t } = useTranslation();
  const selectedTeamEventMembers = teamEventMemberSelectedKeys.length ?? 0;
  const totalTeamEventEmployees = teamEventMembersData?.data.length ?? 0;
  const TeamHeader = () =>
    selectedTeamEventMembers && selectedTeamEventMembers > 0 ? (
      <div className={styles["team-header"]}>
        <div
          className={styles["team-title"]}
        >{`${selectedTeamEventMembers}/${totalTeamEventEmployees} ${t(
          "emp-selected",
        )}`}</div>
        <Space>
          <CustomButton
            onClick={() => onPressChangeTeam()}
            className="basic-btn"
          >
            {t("change-team")}
          </CustomButton>
          <CustomButton
            loading={isLoadingRemoveTeam}
            className="basic-btn"
            onClick={() => {
              onSubmitRemoveTeamMember({
                eventId,
                participantIds: teamEventMemberSelectedKeys,
              });
            }}
          >
            {t("remove-from-team")}
          </CustomButton>
        </Space>
      </div>
    ) : null;

  const handleMenu = ({ key, id }: { key: string; id: string }) => {
    if (key === "remove-team") {
      onSubmitRemoveTeamMember({
        eventId,
        participantIds: [id],
      });
    }
    if (key === "change-team") {
      onPressChangeTeam(id);
    }
  };

  const columns: ColumnsType<
    GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0]
  > = [
    {
      title: t("id"),
      key: "userId",
      dataIndex: "userId",
      sorter: {
        multiple: 1,
      },
      render: (_text, _record, index) =>
        (teamEventMemberCurrentPage - 1) * teamEventMemberPageSize + index + 1,
    },
    {
      title: t("name"),
      dataIndex: "firstName",
      key: "firstName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <CustomAvatar
            isUserProfile={!!record.image}
            imgSrc={record.avatar?.imgPath ?? record.image ?? ""}
          />
          <div
            className={styles["team-member"]}
          >{`${record.firstName} ${record.lastName}`}</div>
        </Space>
      ),
    },
    {
      title: t("company"),
      dataIndex: "company",
      key: "company.companyName",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => (
        <Space>
          <CustomAvatar imgSrc={record.company?.image ?? ""} />
          <div>{record.company?.companyName}</div>
        </Space>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      sorter: {
        multiple: 1,
      },
    },
    ...(!!!teamEventMemberSelectedKeys?.length
      ? [
          {
            dataIndex: "id",
            key: "id",
            align: "center" as "center",
            render: (
              id: string,
              record: GetTeamEventMembersQuery["getTeamEventMembers"]["data"][0],
            ) => (
              <CustomDropDown
                placement="bottomRight"
                menu={{
                  items: [
                    { key: "remove-team", label: t("remove-from-team") },
                    { key: "change-team", label: t("change-team") },
                  ],
                  onClick: ({ key }) =>
                    handleMenu({ key, id: record.participantId ?? "" }),
                }}
              >
                <CustomButton type="link" className="link-btn">
                  <MoreOutlined />
                </CustomButton>
              </CustomDropDown>
            ),
          },
        ]
      : []),
  ];
  return (
    <>
      <CustomModal
        centered
        width={720}
        visible={teamMemberVisibility}
        onCancel={onCloseTeamMember}
        footer={null}
        title={t("edit-team-members", { selectedTeamEventMembers })}
      >
        {TeamHeader()}
        <CustomTable
          loading={loading}
          rowSelection={teamEventMemberRowSelection}
          dataSource={teamEventMembersData?.data}
          columns={columns}
          filters={{
            handleCallback: teamEventMembersFiltersCallBack,
          }}
          searchStyle={{
            borderStyle: "solid",
            borderRadius: 10,
            borderColor: "#adadad",
            width: 270,
          }}
          totalItems={teamEventMembersData?.total}
          btnText={t("add-members")}
          showCustomHeader={!!!teamEventMemberSelectedKeys?.length}
          onClick={onPressAddMember}
          rowKey="participantId"
        />
      </CustomModal>
    </>
  );
};

export default EditTeam;
