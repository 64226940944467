import ContainerHeading from "features/assessment/questions/ContainerHeading/ContainerHeading";
import CollapseAble from "features/assessment/questions/collapseables/CollapseAble";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { GetEventDetails } from "../hooks/useEventViewModel";
import styles from "features/events/css/EventDetail.module.scss";
import CustomImg from "components/custom-img/CustomImg";
import { Regions } from "graphql/_generated/graphql";

interface EventGalleryProps {
  eventData?: GetEventDetails;
  region: Regions;
}

const EventGallery: React.FunctionComponent<EventGalleryProps> = ({
  eventData,
  region,
}) => {
  const { t } = useTranslation();
  return (
    <CollapseAble
      defaultActiveKey={["event-gallery"]}
      headerKey="event-gallery"
      hideExtra={false}
      expandIconPosition="end"
      header={<ContainerHeading text={t("photo-gallery")} />}
    >
      <div className={styles["event-gallery"]}>
        {eventData?.gallery &&
          eventData?.gallery.map((item) => (
            <CustomImg
              draggable={false}
              key={item.id}
              imgSrc={item.image}
              height={270}
              alt="thumbnail image"
              className={styles["img-gallery"]}
              region={region}
            />
          ))}
      </div>
    </CollapseAble>
  );
};

export default EventGallery;
