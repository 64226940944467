import { List, Space } from "antd";
import { Input } from "formik-antd";
import * as React from "react";
import styles from "features/events/css/IndividualLeaderBoard.module.scss";
import { useTranslation } from "react-i18next";
import { BronzeMedalSvg, GoldMedalSvg, SilverMedalSvg } from "assets/icons";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import { getRanks } from "helpers";

interface EditIndividualScoreItemProps {
  itemKey: number;
  id: string;
  userImg: string;
  firstName: string;
  lastName: string;
  companyName: string;
  companyImg: string;
  score: number;
  error: boolean;
  isKSA: boolean;
}

const EditIndividualScoreItem: React.FunctionComponent<
  EditIndividualScoreItemProps
> = ({
  id,
  itemKey,
  firstName,
  lastName,
  userImg,
  companyImg,
  companyName,
  error,
  isKSA,
}) => {
  return (
    <List.Item key={id}>
      <div className={styles["item-grid"]}>
        <div>
          <Space size={14}>
            <div className={styles["item-id"]}>{getRanks(itemKey)}</div>
            <CustomAvatar isUserProfile={isKSA} imgSrc={userImg} />
            <div
              className={styles["sub-title"]}
            >{`${firstName} ${lastName}`}</div>
          </Space>
        </div>
        <Space>
          <CustomAvatar imgSrc={companyImg} />
          <div className={styles["members"]}>{companyName}</div>
        </Space>
        <div className={styles["users"]}>
          <Input
            className="from-input"
            style={{
              width: "96px",
              height: "40px",
              borderColor: error ? "red" : "#80808029",
            }}
            name={`data[${itemKey - 1}].score`}
            type="number"
          />
        </div>
      </div>
    </List.Item>
  );
};

export default EditIndividualScoreItem;
