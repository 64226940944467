import { GraphQLClient } from "graphql-request";
import {
  GetSignedUrlDocument,
  GetSignedUrlQuery,
  Regions,
} from "graphql/_generated/graphql";
import config from "config";

const graphqlUrl = config.api.graphqlUrl;
export const getSignedUrl = async (
  token: string,
  extension: string,
  region: Regions = Regions.Global,
) => {
  try {
    const client = new GraphQLClient(graphqlUrl);
    const response = await client.request(
      GetSignedUrlDocument,
      { extension: extension, withRegion: region },
      { token },
    );
    return (response as GetSignedUrlQuery).getSignedUrl;
  } catch (error) {
    throw error;
  }
};
