import { MoreOutlined } from "@ant-design/icons";
import {
  Space,
  TableColumnsType,
  Tag,
  Select,
  TreeSelect,
  Switch,
  Modal,
} from "antd";
import CheckPermissions from "components/check-permissions/CheckPermissions";
import CustomAvatar from "components/custom-avatar/CustomAvatar";
import CustomButton from "components/custom-button/CustomButton";
import CustomDropDown from "components/custom-dropdown/CustomDropDown";
import CustomMenu from "components/custom-menu/CustomMenu";
import CustomTable from "components/custom-table/CustomTable";
import { PERMS, getLanguage } from "helpers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styles from "../css/Event.module.scss";

import { useLoggedInUser } from "hooks/useLoggedInUser";
import { useEventListViewModel } from "../hooks/useEventListViewModel";
import { getLangDir } from "helpers/getLangDir";
import CustomLanguageSelector from "components/form/custom-language-selector/CustomLanguageSelector";
import { CONFIRM_MODAL_CONFIG } from "helpers/constants";
import { Regions } from "graphql/_generated/graphql";
interface ActivityListProps {}

const EventsList: React.FunctionComponent<ActivityListProps> = () => {
  type MappedEventList = NonNullable<typeof vm.columnsData>[0];
  const { t } = useTranslation();
  const vm = useEventListViewModel();

  const { isSuperAdmin, isViwellAdmin } = useLoggedInUser();

  const handleMenu = ({
    key,
    id,
    record,
  }: {
    key: string;
    id: string;
    record: MappedEventList;
  }) => {
    const { isPlanned, isInProgress, isExpired } = vm.getEventStatuses(record);
    if (key === "event-detail") {
      vm.onPressEventDetail(id, record.language);
    } else if (key === "add-language") {
      vm.onPressAddLang(id, record);
    } else if (key === "edit-event") {
      vm.onPressUpdateEvent(
        id,
        record.language ?? "",
        !!(isInProgress || isExpired),
      );
    } else if (key === "edit-language") {
      vm.onPressUpdateLanguage(id, record.language);
    } else if (key === "delete-event") {
      Modal.confirm({
        ...CONFIRM_MODAL_CONFIG,
        closable: true,
        centered: true,
        title: t("delete-label"),
        content: (
          <>
            <div className="confirm-content">{t("event.delete-confirm")}</div>
          </>
        ),
        width: 506,
        onOk: () => vm.onDeleteEvent(id),
      });
    }
  };

  const handleToggleEvent = (record: MappedEventList) => {
    Modal.confirm({
      ...CONFIRM_MODAL_CONFIG,
      closable: true,
      centered: true,
      title: t("warning"),
      content: (
        <>
          <div className="confirm-content">
            {record.isActive ? t("event.deactivate") : t("event.activate")}
          </div>
        </>
      ),
      width: 506,
      onOk: () => vm.onToggleEventStatus(record.id),
    });
  };

  const columns: TableColumnsType<MappedEventList> = [
    {
      title: t("id"),
      key: "id",
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, index) =>
        record.hasChildren
          ? (vm.currentPage - 1) * vm.pageSize + index + 1
          : null,
    },
    {
      title: t("event.title"),
      key: "title",
      dataIndex: "title",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => {
        return (
          <div className={record?.hasChildren ? "parent-row" : "child-row"}>
            <Space>
              <CustomAvatar
                isUserProfile={vm.region === Regions.Ksa}
                shape="square"
                imgSrc={record?.image ?? ""}
              />
              <span className={`cut-text-${getLangDir(record.title ?? "")}`}>
                {record.title}
              </span>
            </Space>
          </div>
        );
      },
    },
    {
      title: `${t("language")}`,
      key: "language",
      dataIndex: "language",
      sorter: {
        multiple: 1,
      },
      render: (_text, record, _index) => getLanguage(record.language),
    },
    {
      title: t("type"),
      key: "leaderboardType",
      dataIndex: "leaderboardType",
    },
    {
      title: t("location"),
      key: "location",
      dataIndex: "location",
    },
    {
      title: t("starting-date"),
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        multiple: 1,
      },
      render: (startDate: string) => moment(startDate).format("MMMM Do YYYY"),
    },
    {
      title: t("expiry-date"),
      dataIndex: "expiryDate",
      key: "expiryDate",
      sorter: {
        multiple: 1,
      },
      render: (expiryDate: string) => moment(expiryDate).format("MMMM Do YYYY"),
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (isActive: boolean, record) => {
        if (isActive) {
          const { isPlanned, isInProgress, isExpired } =
            vm.getEventStatuses(record);
          if (isPlanned) {
            return (
              <Tag className={`${styles["status-circle-div"]} planned-tag`}>
                <div className={styles["planned-circle"]}></div>
                {t("planned")}
              </Tag>
            );
          } else if (isInProgress) {
            return (
              <Tag className={`${styles["status-circle-div"]} inprogress-tag`}>
                <div className={styles["inprogress-circle"]}></div>
                {t("inprogress")}
              </Tag>
            );
          } else if (isExpired) {
            return <Tag className="de-active-tag">{t("expired")}</Tag>;
          }
        } else return <Tag className="de-active-tag">{t("deactivated")}</Tag>;
      },
    },
    {
      align: "center" as "center",
      title: t("activation"),
      dataIndex: "id",
      key: "id",
      render: (_text, record, _index) => (
        <>
          <CheckPermissions permission={PERMS.EVENT_STATUS}>
            <Switch
              className="custom-switch"
              defaultChecked
              checked={record.isActive}
              onClick={() => handleToggleEvent(record)}
            />
          </CheckPermissions>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "Actions",
      align: "center",
      render: (id, record) => {
        const langActions = [{ key: "edit-language", label: "Edit Language" }];

        const { isPlanned, isInProgress, isActive } =
          vm.getEventStatuses(record);

        const mainActions = [
          { label: t("event.details"), key: "event-detail" },
          {
            label: t("event.edit"),
            key: "edit-event",
          },
          { label: t("event.add-language"), key: "add-language" },
          {
            label: t("event.delete"),
            key: "delete-event",
            disabled: isActive && isInProgress,
          },
        ];
        return (
          <CheckPermissions
            permission={PERMS.UPDATE_EVENT || PERMS.DELETE_EVENT}
          >
            <CustomDropDown
              menu={{
                items: record?.hasChildren ? mainActions : langActions,
                onClick: ({ key }) => handleMenu({ key, id, record }),
              }}
            >
              <CustomButton type="link" className="link-btn">
                <MoreOutlined />
              </CustomButton>
            </CustomDropDown>
          </CheckPermissions>
        );
      },
    },
  ];
  return (
    <>
      <CustomTable
        headerTitle={t("events")}
        btnText={t("add-new-event")}
        permission={PERMS.ADD_NEW_EVENT}
        onClick={vm.onPressAddEvent}
        expandIconColumnIndex={1}
        columns={columns}
        dataSource={vm.columnsData}
        loading={vm.loading.isLoadingEventList}
        totalItems={vm.total}
        rowKey={(record) =>
          record.hasChildren ? record.id : `${record?.id}_${record?.title}`
        }
        filters={{
          handleCallback: vm.filtersCallBack,
          date: [
            {
              applyAs: "and",
              key: "createdAt",
              comparison: "Range",
              placeholder: t("starting-date"),
            },
            {
              applyAs: "and",
              key: "expiryDate",
              comparison: "Range",
              placeholder: t("expiry-date"),
            },
          ],
          status: {
            applyAs: "and",
            key: "isActive",
            comparison: "Equal",
          },
          manualFilters: [
            ...(isSuperAdmin || isViwellAdmin
              ? [
                  {
                    key: "participants.clientId",
                    comparison: "Equal",
                    applyAs: "and",
                    render: (onChangeCallback: () => {}, value: string) => (
                      <TreeSelect
                        allowClear
                        showArrow
                        treeNodeFilterProp="companyName"
                        treeLine={true && { showLeafIcon: false }}
                        className="filter-select"
                        treeData={vm.companiesData}
                        placeholder={"Company: All"}
                        fieldNames={{ label: "companyName", value: "id" }}
                        onChange={onChangeCallback}
                        value={value}
                      />
                    ),
                  },
                ]
              : []),
            {
              key: "leaderboardType",
              comparison: "Equal",
              applyAs: "and",
              render: (onChangeCallback) => (
                <Select
                  options={vm.eventTypes}
                  className="filter-select"
                  onChange={onChangeCallback}
                  allowClear
                  placeholder={t("type-all")}
                />
              ),
            },
          ],
        }}
      />
      {vm.langVisibility && (
        <CustomLanguageSelector
          title={
            vm.isAddLanguage ? undefined : `${t("event")} ${t("main-language")}`
          }
          label={vm.isAddLanguage ? undefined : t("event")}
          saveText={vm.isAddLanguage ? undefined : t("add-event")}
          visible={vm.langVisibility}
          setVisible={vm.onCloseLangDialog}
          onSubmit={vm.onLanguageSubmit}
          data={vm.eventRecord}
        />
      )}
    </>
  );
};

export default EventsList;
