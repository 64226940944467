import * as Yup from "yup";
import * as Validations from "./common/validations";

export const unitsSchema = Yup.object().shape({
  unit: Validations.requiredFieldWithoutSpaces("unit"),
  description: Validations.requiredFieldWithoutSpaces("description"),
  translations: Yup.array().of(
    Yup.object().shape({
      lang: Validations.required("language"),
      unit: Validations.required("unit"),
      description: Validations.required("description"),
    }),
  ),
});
